import React, {useEffect, useState} from 'react';
export const Claims = (props: any) => {
    console.log('In NextgenUi Claims: props=', props);
    const {context} = props;
    const ref = React.useRef(null);
    const [viewName, setViewName] = useState<string>('claimsSearch');
    let newContext = JSON.stringify(context);
    const [claimContext, setClaimContext] = React.useState(newContext);
    useEffect(() => {
        const handleEvent = (e: any) => {
            console.log('In NextgenUi Claims: handleEvent', e);
            const {detail} = e;

            // eslint-disable-next-line default-case
            switch (detail.type) {
                case '[CLAIMS] ROUTE': {
                    console.log('In NextgenUi Claims: detail=', detail);
                    newContext = JSON.stringify({...context, searchDetailParam: detail.payload.searchDetailParam});
                    console.log('In NextgenUi Claims: newContext=', newContext);
                    setClaimContext(newContext);
                    if (detail.payload.viewName) {
                        setViewName(detail.payload.viewName);
                    }
                    break;
                }
            }
        };

        const curentRef: any = ref.current;
        console.log('In NextgenUi Claims:: curentRef=', curentRef);
        setClaimContext(JSON.stringify(props.context));

        if(curentRef) {
            curentRef.addEventListener('event', handleEvent);
        }
        return () => {
            curentRef.removeEventListener('event', handleEvent);
        };
    }, [props.context]);
    return (
        // @ts-ignore
        <claims-micro-product ref={ref}  view={viewName}  id="claims-webcomponent" text="Claims" context={claimContext}/>
    );
};

export default Claims;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { programInformations } from "./utils/OptumPrograms";
import { generateFetchWithSession } from "./security/AuthentifiedFetch";
import * as AuthProxy from './security/AuthProxy';
import Login from './Login';
import Logout from "./layout/logout/Logout";
import loadWebcomponents from './loadScripts';

const fileConfig = './navigation.json';
const envConfig = './environments.json';

const OHID = 'ohid';
const TESTING = 'testing';
const CODE = 'code';

/*if (process.argv[2] === 'start:proxy' && process.env.NODE_ENV === 'development') {
    // @ts-ignore
    window['MP_API_BASE_PATH'] = 'http://localhost:3001';
} else {
    console.log('no local proxy');
}*/

fetch(envConfig)
    .then((res: any): any => {
        return res.json() as programInformations;
    }).then((env: programInformations): any => {
        // @ts-ignore
    const environment = env[process.env.REACT_APP_API_ENV];
    window['environments'] = environment;
    loadWebcomponents(environment);
    // @ts-ignore
    window['version'] = env.version;
    });

fetch(fileConfig)
    .then((res: any): any => {
        return res.json();//as programInformations;
    })
    .then((env: any): any => {
        // @ts-ignore
        const environments = window['environments'] ;

        if(environments !== undefined &&  environments.no_auth !== undefined 
             && environments.no_auth == true){
            alert("WARNING! NexGen is running without authentication!");
            AuthProxy.setAuthType(AuthProxy.encrypt(TESTING));
            AuthProxy.setSessionId(AuthProxy.encrypt(TESTING));
        }
        const query = new URLSearchParams(window.location.search);
        const code = query.get(CODE);

        //look for prompt param if coming from client portal
        const query2=new URLSearchParams(window.location.search)
        const prompt=query2.get("prompt")

        // user has come from another application and is already signed in with OHID
        // supress the first sign on screen and kick off OHID call 
        if(prompt){
            
            if (
              (typeof sessionStorage.getItem("sessionId") == "undefined" ||
                sessionStorage.getItem("sessionId") == null) &&
              (code === null || code === "undefined")
            ) {
              //set auth type to OHID 
              AuthProxy.setAuthType(AuthProxy.encrypt(OHID));
              
              //initiate login
              //AuthProxy.loginRedirect();
            }
          }

        var authType= AuthProxy.getAuthType();
        if(authType ==null || authType ===undefined){
            AuthProxy.setAuthType(AuthProxy.encrypt(OHID));
        }
        
        if (env['authentication'] === 'auth-proxy') {
            if (!AuthProxy.getSessionId() && code) {
                sessionStorage.removeItem('logout');
                if(AuthProxy.getAuthType() == '') {
                    AuthProxy.setAuthType(AuthProxy.encrypt(OHID));
                }
                AuthProxy.session(code)
                    .then((res: any) => res.json())
                    .then((data) => {
                        AuthProxy.setSessionId(AuthProxy.encrypt(data['sessionId']));
                        window.fetch = generateFetchWithSession(data['sessionId']);
                        window.location.href = `./`;
                        // @ts-ignore
                        window['navigation'] = env as programInformations;

                        return ReactDOM.render(
                            <React.StrictMode>
                                <App />
                            </React.StrictMode>,
                            document.getElementById('root')

                        );
                    })
            } else if (sessionStorage.getItem('logout')) {
                sessionStorage.removeItem('logout');
                return ReactDOM.render(
                    <React.StrictMode>
                        <Logout />
                    </React.StrictMode>,
                    document.getElementById('root')
                );
            }
            else if (AuthProxy.getSessionId()) {
                // @ts-ignore
                window.fetch = generateFetchWithSession(AuthProxy.getSessionId());

                // @ts-ignore
                window['navigation'] = env as programInformations;

                return ReactDOM.render(
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>,
                    document.getElementById('root')

                );
            } else if (!AuthProxy.getSessionId() && !code) {
                return ReactDOM.render(
                    <React.StrictMode>
                        <Login />
                    </React.StrictMode>,
                    document.getElementById('root')
                );
            }
        }
    });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

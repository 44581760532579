import * as React from 'react';
import { Card } from 'primereact/card';
import './Logout.css';
import * as AuthProxy from '../../security/AuthProxy';

interface LogOutProps { }

interface LogOutState { 
    signOutMessage: String;
}
export default class Logout extends React.Component<LogOutProps, LogOutState> {
    constructor(props: LogOutProps) {
        super(props);
        this.state = {
            signOutMessage: "Please click the Browser Refresh button to re-login."
        } 
    }

    getConfProfile(screenId: any){
        AuthProxy.callConfService(screenId)
          .then((res: any) => res.json())
          .then((data) => {
            console.log(data);
            var parsable = "";
            if(data[0].screenConfiguration){
              parsable = data[0].screenConfiguration;
            }
            this.setState({signOutMessage: JSON.parse(parsable).signOutMessage});
        })
      }

    componentDidMount(): void {
        this.getConfProfile(8);
        const sessionId = AuthProxy.getSessionId();
        console.log('componentDidMount sessionId=', sessionId);
        if (sessionId) {
            AuthProxy.logoutUser()
                .then((res: any) => res.json())
                .then((data) => {
                    console.log('inside logoutUser callback sessionStorage=', sessionStorage)
                    if (!sessionStorage.getItem('logout')) {
                        sessionStorage.setItem('logout', 'true');
                        sessionStorage.removeItem('sessionId');
                        sessionStorage.removeItem('authType');
                        const logoutUrl = JSON.stringify(data).replace(/\"/g, "");
                        const redirectUri = AuthProxy.getHostUri();
                        console.log("logout url" + logoutUrl, 'AuthProxy.getAuthType()=', AuthProxy.getAuthType());
                        if (AuthProxy.getAuthType() === "msid") {
                            AuthProxy.setAuthType(AuthProxy.encrypt('ohid'));
                            console.log('logouturl=', `${logoutUrl}&TargetResource=${redirectUri}`);
                            window.location.href = `${logoutUrl}&TargetResource=${redirectUri}`;
                        } else {
                            AuthProxy.setAuthType(AuthProxy.encrypt('ohid'));
                            console.log('logouturl=', `${logoutUrl}&post_logout_redirect_uri=${redirectUri}`);
                            window.location.href = `${logoutUrl}&post_logout_redirect_uri=${redirectUri}`;
                        }
                        console.log(`logout successfully completed`);
                    }
                });
        }
    }

    render(): React.ReactElement {
        return (
            <React.Fragment>
                <div className="logoutPage">
                    <Card subTitle="Sign Off Successful" className="card ui-card-shadow">
                        {this.state.signOutMessage}
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

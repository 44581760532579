// Convert string to hex
export function convertStringToEbcdicHex(input: any, size: number) {
    if (input == null) {
        input = "";
    }
    if (typeof input !== 'string') {          
            input = String(input);
    }
    var length = input.length;
    var buffer = "";
    for (var i = 0; i < size; i++) {
        if (i > length - 1) {
        buffer += "40"; // right pad with spaces
        } else {          
          buffer += convertCharacterToEbcdicHex(input.substr(i, 1));
        }
    }
    return buffer;
}

// using a map convert unicode character to EBCDIC Hex code
export function convertCharacterToEbcdicHex(input: any) { 
    // Characters to EBCDIC Hex codes
    var map = {
        " ": "40",
        "[": "4A",
        ".": "4B",
        "<": "4C",
        "(": "4D",
        "+": "4E",
        "&": "50",
        "!": "5A",
        "$": "5B",
        "*": "5C",
        ")": "5D",
        ";": "5E",
        "^": "5F",
        "-": "60",
        "/": "61",
        "|": "4F",
        ",": "6B",
        "%": "6C",
        "_": "6D",
        ">": "6E",
        "?": "6F",
        "`": "79",
        ":": "7A",
        "#": "7B",
        "@": "7C",
        "'": "7D",
        "=": "7E",
        '"': "7F",
        "a": "81",
        "b": "82",
        "c": "83",
        "d": "84",
        "e": "85",
        "f": "86",
        "g": "87",
        "h": "88",
        "i": "89",
        "j": "91",
        "k": "92",
        "l": "93",
        "m": "94",
        "n": "95",
        "o": "96",
        "p": "97",
        "q": "98",
        "r": "99",
        "~": "A1",
        "s": "A2",
        "t": "A3",
        "u": "A4",
        "v": "A5",
        "w": "A6",
        "x": "A7",
        "y": "A8",
        "z": "A9",
        "{": "C0",
        "A": "C1",
        "B": "C2",
        "C": "C3",
        "D": "C4",
        "E": "C5",
        "F": "C6",
        "G": "C7",
        "H": "C8",
        "I": "C9",
        "}": "D0",
        "J": "D1",
        "K": "D2",
        "L": "D3",
        "M": "D4",
        "N": "D5",
        "O": "D6",
        "P": "D7",
        "Q": "D8",
        "R": "D9",
        "\\": "E0",
        "S": "E2",
        "T": "E3",
        "U": "E4",
        "V": "E5",
        "W": "E6",
        "X": "E7",
        "Y": "E8",
        "Z": "E9",
        "0": "F0",
        "1": "F1",
        "2": "F2",
        "3": "F3",
        "4": "F4",
        "5": "F5",
        "6": "F6",
        "7": "F7",
        "8": "F8",
        "9": "F9",
    };
        // if no input or length is 0
    if (!input || input.length < 1) {
        return ""; // do nothing
    }
    // TODO - what if it is not mapped
    return map[input];
}


export function convertNumberToPackedHex(input: any, format: any) {
    var matches = format.toString().match(/^(\d+)(\.(\d+))?$/);
    if (!matches) {
      return null;
    }
    var length = parseInt(matches[1]);
    var decimal = parseInt(matches[3]);
    if (isNaN(decimal)) {
      decimal = 0;
    }
    if (length < 1) {
      return null;
    }

    input = parseFloat(input);
    if (isNaN(input)) {
      input = 0;
    }
    var sign = input < 0 ? "D" : "F";
    // console.log("input: " + input + " format: " + format + " sign: " + sign + " length: " + length + " decimal: " + decimal)

    input = input.toFixed(decimal).toString().replace(".", "").replace("-", "");

    // left pad with zeros
    input = "0".repeat(length) + input;
    input = input.substr(-length);

    input += sign;

    // left pad with 0 to an even number of characters
    if (input.length % 2 === 1) {
      input = "0" + input;
    }

    return input;
  };

// Take a Java date and converts back to green screen expected value for program call
// Expected format on the incoming (from java) date is 2005-07-01 (yyyy-mm-dd)
export function convertDateToPackedHex(input: string, format: string) {
    var matches = input.match(/^(\d\d\d\d)\-(\d\d)\-(\d\d)$/);
    if (!matches) {
        // recursion - be careful to not loop infinitely 
        return convertDateToPackedHex("0001-01-01", format)
    }
    // If DT# do nothing, as format is same as Java
    if (format === "DT#") {
        return input;
    } else if (format === "DTE") {
        // special case 0001-01-01
        if (input === "0001-01-01") {
        return convertNumberToPackedHex(
            -9990101,
            7
        );
        }
        // convert year based on 1900 - CYY
        var yearInt = parseInt(matches[1]) - 1900;
        year = yearInt.toString();
        var month = matches[2];
        var day = matches[3];
        return convertNumberToPackedHex(
        year + month + day,
        7
        );
    } else if (format === "DT8") {
        var year = matches[1];
        var month = matches[2];
        var day = matches[3];
        return convertNumberToPackedHex(
        year + month + day,
        8
        );
    } else {
        return null;
    }
};

// Given an Array of parameters return formatted paramters ready to send to RxClaim Web (Presto)
  // All parameters will be converted to HEX
  // Example of successful call XIPRESTO 'DEV' RCPLN013I ' ' X'C3D4D6D34040404040400010101F' => non hex value'CMOL      010101' 
  export function formatWebParameters(parameters: Array) {
    console.log("Starting formatWebParameters");
    console.log(parameters);

    var returnParameters = [];
    var paramIndex = -1;

    parameters.forEach((parameter: any) => { 
      var paramNumber = parameter.param + 2; // add 2 cause zero indexed and first param is return code

      // Check for date
      if(isDateParameter(parameter)) {
        parameter.valueEncoded = convertDateToPackedHex(parameter.value, parameter.type);
      }
      // Check numeric
      else if(isNumericParameter(parameter)) {
        parameter.valueEncoded = convertNumberToPackedHex(parameter.value, parameter.length);
      }
      else {
        parameter.valueEncoded = convertStringToEbcdicHex(parameter.value, parameter.length);
      }

      // if not current index start new parm
      if(parameter.param > paramIndex) {
        var webParameter = {};
        webParameter.paramNumber = paramNumber;
        webParameter.value = parameter.valueEncoded;
        paramIndex = parameter.param;
        returnParameters.push(webParameter);
      }
      // If the same index concatenate to last param (these are combined parameter types)
      else {
      	// Since paramters are in the right order we assume we are adding to the previously looped parameter
      	returnParameters[returnParameters.length - 1].value += parameter.valueEncoded;
      }

    });

    // wrap hex parameters for command line
    returnParameters.forEach((returnParameter: any) => { 
      returnParameter.value = "X'" + returnParameter.value + "'";
    });

    console.log("Ending formatWebParameters");
    console.log(parameters);
    console.log(returnParameters);

    return returnParameters;
  }

  // Return true if this a known date format
  export function isDateParameter(parameter: JSON) {
    return (parameter.type === "DTE" || parameter.type === "DT#" || parameter.type === "DT8") ? true : false;
  }
  // Return true if this a standard numeric
  export function isNumericParameter(parameter: JSON) {
    return (parameter.dataType === "P") ? true : false;
  }


export function keyIsEligibleForMenuFormingOperations(key: string, map: Map<string, string[]>) {
  //key is combination of parent and its sub menu eg pharmacynetwork.networksearch
  let parentMenuLabel = key.split(".")[0];  //extpbm
  let childMenuLabel = key.split(".")[1];   //sth
  let parentMenuChildren = map.get(parentMenuLabel);
  if (parentMenuChildren !== undefined && parentMenuChildren.length) {
    if (parentMenuChildren.length > 0) {
      //check array contains all.access.grant if yes then check that sub menu has deny or not other wise return true
      if (isAccessGrantFoundOperations(parentMenuChildren, parentMenuLabel) ||
        isAllAccessGrantFoundParentLabel(parentMenuChildren, parentMenuLabel)) {
        if (checkChildMenuLabelEntryFound(childMenuLabel, parentMenuChildren)) {
          //now check for childMenuLabel in array is it found and it should not contain deny
          if (checkForChildMenuLabelInParentMenuChildren(childMenuLabel, parentMenuChildren)) {
            return true;
          } else {
            if (checkItHasAnyGrant(childMenuLabel, parentMenuChildren)) {
              return true
            }
            return false;
          }
        } else {
          //since childLabel menu entry not found in abilities so it is eligible for add
          return true;
        }
      } else {
        //now check for childMenuLabel in array.If it found then it should not contain deny
        //[if it contain access.deny then check it has read.gran or other]
        if (checkForChildMenuLabelInParentMenuChildren(childMenuLabel, parentMenuChildren)) {
          return true;
        } else {
          if (checkItHasAnyGrant(childMenuLabel, parentMenuChildren)) {
            return true
          }
          return false;
        }
      }
    }
  } else {
    return false;
  }
}

export function keyIsEligibleForMenuForming(key: string, map: Map<string, string[]>) {
  if(key === null || key === undefined) return false;
  //key is combination of parent and its sub menu
  let parentMenuLabel = key?.split(".")[0];
  let childMenuLabel = key?.split(".")[1];
  let parentMenuChildren = map.get(parentMenuLabel);
  if (parentMenuChildren !== undefined && parentMenuChildren.length) {
    if (parentMenuChildren.length > 0) {
      //check array contains all.access.grant if yes then check that sub menu has deny or not other wise return true
      if (isAllAccessGrantFoundParentLabel(parentMenuChildren, parentMenuLabel)) {
        if (checkChildMenuLabelEntryFound(childMenuLabel, parentMenuChildren)) {
          //now check for childMenuLabel in array is it found and it should not contain deny
          if (checkForChildMenuLabelInParentMenuChildren(childMenuLabel, parentMenuChildren)) {
            return true;
          } else {
            if(checkItHasAnyGrant(childMenuLabel, parentMenuChildren)){
              return true
            }
            return false;
          }
        } else {
          //since childLabel menu entry not found in abilities so it is eligible for add
          return true;
        }
      } else {
        //now check for childMenuLabel in array.If it found then it should not contain deny
        //[if it contain access.deny then check it has read.gran or other]
        if (checkForChildMenuLabelInParentMenuChildren(childMenuLabel, parentMenuChildren)) {
          return true;
        } else {
          if(checkItHasAnyGrant(childMenuLabel, parentMenuChildren)){
            return true
          }
          return false;
        }
      }
    }
  } else {
    return false;
  }
}

export function checkForChildMenuLabelInParentMenuChildren(childMenuLabel: string, parentMenuChildren: string[]) {
  //return false case 
  // access.deny found return false 
  // access.deny not found and if (read.grant ,insert.grant, modify.grant,delete.grant found) then return true

  let dataArray = collectArrayData(parentMenuChildren, childMenuLabel);
  if (dataArray.length === 0) {
    return false;
  } else {
   
    //check if it contains access.deny
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].toString()===childMenuLabel+'.access.deny' || dataArray[i].toString()===childMenuLabel+'.all.access.deny') {
        return false;
      }
    }
    //logic comes here then access.deny not found, now check upper level access provided
    if(isAllAccessGrantFound(parentMenuChildren)){
        return true;// then return true
    }else {
     //If all access not granted then  
    //check it contains  read.grant || insert.grant || modify.grant || delete.grant 
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].toString()===childMenuLabel+'.access.grant' 
        || dataArray[i].toString()===childMenuLabel+'read.grant'
        || dataArray[i].toString()===childMenuLabel+'insert.grant' 
        || dataArray[i].toString()===childMenuLabel+'modify.grant'
        || dataArray[i].toString()===childMenuLabel+'delete.grant' 
        || dataArray[i].toString() === childMenuLabel+".all.access.grant"
        || dataArray[i].toString() === childMenuLabel+".all.read.grant"
        || dataArray[i].toString() === childMenuLabel+".all.delete.grant"
        || dataArray[i].toString() === childMenuLabel+".all.insert.grant"
        || dataArray[i].toString() === childMenuLabel+".all.modify.grant") {       
        return true;
      }else {
        return false;
      }
    }
  }
  }

}

export function collectArrayData(parentMenuChildren: string[], childMenuLabel: string) {
  var result = new Array;
  for (let i = 0; i < parentMenuChildren.length; i++) {
    if (parentMenuChildren[i].toString().split(".")[0] === childMenuLabel) {
      result.push(parentMenuChildren[i]);
    }
  }
  return result;
}

export function isAllAccessGrantFound(parentMenuChildren: string[]) {
  for(let i=0; i<parentMenuChildren.length; i++){
    if(checkIsAccessGranted(parentMenuChildren[i])){
      return true;
    }
  }
  return false;
}

export function checkChildMenuLabelEntryFound(childMenuLabel: string, parentMenuChildren: string[]) {
  let dataArray = collectArrayData(parentMenuChildren, childMenuLabel);
  if (dataArray.length > 0) {
    return true;
  }else {
    return false;
  } 
}

export function checkInPricingMenu(key: string, pricingMenu: any[]) {
  for (let i = 0; i < pricingMenu?.length; i++) {
    if (pricingMenu[i]?.label === key) {
      
      return true;
    }
  }
  return false;
}

export function checkInPlanMenu(key: string, planMenu: any[]) {
  for (let i = 0; i < planMenu?.length; i++) {
    if (planMenu[i]?.label === key) {
      return true;
    }
  }
  return false;
}

export function checkInProviderMenu(key: string, providerMenu: any[]) {
  for (let i = 0; i < providerMenu.length; i++) {
    if (providerMenu[i].label === key) {
      
      return true;
    }
  }
  return false;
}

export function checkInOperationsMenu(key: string, operationsMenu: any[]) {
  for (let i = 0; i < operationsMenu.length; i++) {
    if (operationsMenu[i].label === key || operationsMenu[i].label.toLowerCase().split(" ").join() === key) {
      return true;
    }
  }
  return false;
}

export function checkIsAccessGranted(val:string){
  let valArray= val.split(".");
  let length= valArray.length;
  let comparedValue=valArray[length-3]+"."+valArray[length-2]+"."+valArray[length-1];
  if(valArray[length-3]==='all'){
    if('all.access.grant'===comparedValue || 'all.read.grant'===comparedValue 
    || 'all.modify.grant'===comparedValue || 'all.insert.grant'===comparedValue || 'all.delete.grant'===comparedValue ){
  return true;
}else{
  return false;
}
  }else {
    let newComparedValue=valArray[length-2]+"."+valArray[length-1];
    if('access.grant'===newComparedValue || 'read.grant'===newComparedValue 
    || 'modify.grant'===newComparedValue || 'insert.grant'===newComparedValue || 'delete.grant'===newComparedValue ){
  return true;
}else{
  return false;
}
  }

}

export function isAllAccessGrantFoundParentLabel(parentMenuChildren: string[], parentMenuLabel: string) {
  for(let i=0; i<parentMenuChildren.length; i++){
    if(parentMenuLabel === 'pricing' && (
      parentMenuChildren[i]==="all.access.grant"
      || parentMenuChildren[i]==="all.read.grant"
      || parentMenuChildren[i]==="all.modify.grant"
      || parentMenuChildren[i]==="all.insert.grant"
      || parentMenuChildren[i]==="all.delete.grant"
      )){
      
      return true;
    }
    if(parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.access.grant" 
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.read.grant"
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.modify.grant"
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.insert.grant"
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.delete.grant"){
      return true;
    }
  }
  
  return false;
}

export function isAccessGrantFoundOperations(parentMenuChildren: string[], parentMenuLabel: string) {
  //parentMenuLabel-pharmacy network

  for (let i = 0; i < parentMenuChildren.length; i++) {
    if(parentMenuLabel == "Claims") {
      if(parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.access.grant" 
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.read.grant"
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.modify.grant"
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.insert.grant"
      || parentMenuLabel+"."+parentMenuChildren[i]===parentMenuLabel+".all.delete.grant"){
      return true;
    }
    }
    if (parentMenuLabel + "." + parentMenuChildren[i] === parentMenuLabel + ".access.grant"
      || parentMenuLabel + "." + parentMenuChildren[i] === parentMenuLabel + ".read.grant"
      || parentMenuLabel + "." + parentMenuChildren[i] === parentMenuLabel + ".modify.grant"
      || parentMenuLabel + "." + parentMenuChildren[i] === parentMenuLabel + ".insert.grant"
      || parentMenuLabel + "." + parentMenuChildren[i] === parentMenuLabel + ".delete.grant") {
      return true;
    }
  }

  return false;
}

export function checkItHasAnyGrant(childMenuLabel: string, parentMenuChildren: string[]) {
  let dataArray = collectArrayData(parentMenuChildren, childMenuLabel);
  if (dataArray.length === 0) {
    return false;
  } else {
    //check it contains  read.grant || insert.grant || modify.grant || delete.grant 
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].toString()===childMenuLabel+'.access.grant' 
        || dataArray[i].toString()===childMenuLabel+'.read.grant'
        || dataArray[i].toString()===childMenuLabel+'.insert.grant' 
        || dataArray[i].toString()===childMenuLabel+'.modify.grant'
        || dataArray[i].toString()===childMenuLabel+'.delete.grant' 
        || dataArray[i].toString() === childMenuLabel+".all.access.grant"
        || dataArray[i].toString() === childMenuLabel+".all.read.grant"
        || dataArray[i].toString() === childMenuLabel+".all.delete.grant"
        || dataArray[i].toString() === childMenuLabel+".all.insert.grant"
        || dataArray[i].toString() === childMenuLabel+".all.modify.grant") {       
        return true;
      }
    }
  }
  return false;
}
import React, {FC} from "react";
import ReactDOM from 'react-dom';
import './AppFooter.css';
import 'primeflex/primeflex.css';
import * as AuthProxy from '../../security/AuthProxy';
import Logout from "../../layout/logout/Logout";
import { Footer } from "@better-ui/react/";
import { Button } from "@better-ui/react";
import "@better-ui/styles/optum/optum.css";
import classNames from "classnames";


declare global {
  interface Window { runConfig: any; }
}

export interface AppFooterState {
  currentDateTime: String;
  userProfile: undefined;
  displayUser: String;
  userAliasName: String;
}

class AppFooter extends React.Component<{}, AppFooterState> {
  private logoSrc = './images/Optum-Rx-wht-RGB.svg';
  private intervalID: any;

  constructor(props: any) {
    super(props);
    this.state = { currentDateTime: Date().toLocaleString(), userProfile: undefined, displayUser: "", userAliasName: ""};
  }
  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      currentDateTime: new Date().toLocaleString('en-US', { hour12: false })
    });
  }

  openHelpText() {
    const helpPage = "/help/index.htm#t=Misc%2FWelcomeRxC_NextGen.htm";
    window.open(
      helpPage,
      'Help'
    );
    return;
  }




  render(): React.ReactElement {
    console.log()
    let env = process.env.REACT_APP_API_ENV;
    let displayUser;
    let logoutDisplay;
    let version = window["version"];
    const doLogout = () => {
      return ReactDOM.render(
        <React.StrictMode>
          <Logout />
        </React.StrictMode>,
        document.getElementById('root')
      );
    }
    if (this.state.userProfile && AuthProxy.getSessionId()) {
       // only want to display firstname
       const fullName = this.state.displayUser.split(" ");
       const firstName = fullName[0];
      displayUser = <button> Hello {firstName} </button>;
      logoutDisplay = <span className="logout"><span className="section-divider-right"></span><button onClick={doLogout}>Sign Out</button></span>;
    } else {
      displayUser = <button> Hello Guest </button>;
    }


    if (env == undefined) {
      env = "local";
    }


  
    const DefaultLogo: FC<{theme: string}> =({theme}) =>{
      //apply environment button dynamically based on the environment

      if (env == "local") {

        return <div className="bui-button footer-btn-local" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("dev")) { 

        return <div className="bui-button footer-btn-dev" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("qa") ) { 

        return <div className="bui-button footer-btn-qa" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("stg") ) { 

        return <div className="bui-button footer-btn-stg" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      else  { 

        return <div className="bui-button footer-btn-prod" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
    }
    
  }
  
  
   
    
    
  
    return (
      <Footer className="footerContainer" /*logoContent={<DefaultLogo theme={"bui-uhc-theme"}/>}*/ /*linkConfig={{links: [{href:'#', label: <DefaultLogo theme={"bui-uhc-theme"} />, className: "linkStyle"}]}}*/>
      
       <DefaultLogo theme={"bui-uhc-theme"} ></DefaultLogo>
      </Footer>

      
    );
  }
}

export default AppFooter;
import axios from 'axios';

const CryptoJs = require('crypto-js');
const fs = require("fs");
const path = require("path");


export function session(code: string): Promise<Response> {
    const requestBody = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'code': code })
    };
    return fetch(`./session?redirect_uri=${getHostUri() + '/'}&auth_type=${getAuthType()}`, requestBody);
}

export function loginRedirect() {
    window.location.href = `./login?redirect_uri=${getHostUri() + '/'}&auth_type=${getAuthType()}`;
}

export function portunusAbilities() {
    // @ts-ignore
    const env = window['environments'];
    if(env !== undefined && env.no_auth == true){
        const abilities = "./local/abilities.json";
        return fetch(abilities);
    }

    return fetch(`./portunus/abilities`, {
        headers: {
            'session-id': getSessionId() as string,
            'authtype': getAuthType() as string,
            'modulename': env?.module as string,
        }
    });
}

export function getPortunusAbilities(tenantId:string) {
    // @ts-ignore
    console.log(tenantId)
    
    // @ts-ignore
    const env = window['environments'];
    if(env !== undefined && env.no_auth == true){
        const abilities = "./local/abilities.json";
        return fetch(abilities);
    }
    return fetch(`./portunus/abilities`, {
        headers: {
            'session-id': getSessionId() as string,
            'authtype': getAuthType() as string,
            'modulename': tenantId,
        }
    });
}

export function getRoles(mappedRoles: any) {
    let roles = [];
    for(let i=0; i<mappedRoles.length; i++) {
        let map = mappedRoles[i].mappingsRespList;
        for(let j=0; j<map.length; j++) {
            roles.push(map[j].role);
        }
    }
    return roles;
}

export function callUserAlias(userProfile: any, abilities: any) {
    console.log(abilities);
    let userid = userProfile.msid;
    let roles = userProfile.roles;
    let rolesList = [];
    if(roles != null) {
        for(let i=0;i<roles.length;i++) {
            if(roles[i].startsWith("CN=RX")) {
                let trimmedRole = roles[i].substring(roles[i].indexOf("=")+1, roles[i].indexOf(","));
                rolesList.push(trimmedRole);
            }
        }
    }
    if(!(getAuthType() == 'msid')) {
        rolesList = abilities;
        userid = userProfile.preferred_username
    }

    // @ts-ignore
    const env = window['environments'];
    console.log(env);
    console.log("uuid:" + userid);
    console.log("authtype" + getAuthType());
    let url = '/user-alias-service/create';

    if(process.env.REACT_APP_API_ENV == 'stg' || process.env.REACT_APP_API_ENV == 'prd') {
        url = './user-alias-service/user-alias-service/create';
    }
    return fetch(url, {
        method: "POST",
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(buildUserAliasRequest(userProfile, userid, rolesList))
    })
}

export function buildUserAliasRequest(userProfile: any, userId: any, roleList: any[]) {
    let userRequest = {
        "loginType": getAuthType(),
        "userId": userId,
        "firstName": userProfile.given_name,
        "lastName": userProfile.family_name,
        "emailAddress": userProfile.email,
        "sub": userProfile.sub,
        "roles": roleList
    }
   
    return userRequest;    
}

export function callConfService(screenId: any) {

    // @ts-ignore
    const env = window['environments'];
    console.log(env);
    let url = '/screen-config-service/getScreenConfig';

    if(process.env.REACT_APP_API_ENV == 'stg' || process.env.REACT_APP_API_ENV == 'prd') {
        url = './user-alias-service/screen-config-service/getScreenConfig';
    }
    url = url + "?screenId=" + screenId;

    return fetch(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    })
}

export function callUserPrefService(screenId: any, userAliasName: any) {

    // @ts-ignore
    const env = window['environments'];
    console.log(env);
    var authTypeCd;
    if(getAuthType() === 'msid'){
        authTypeCd = 'M';
    } else{
        authTypeCd = 'O';
    }

    let url = '/user-preference-service/getUserPreferences';

    if(process.env.REACT_APP_API_ENV == 'stg' || process.env.REACT_APP_API_ENV == 'prd') {
        url = './user-alias-service/user-preference-service/getUserPreferences';
    }
    url = url + "?screenId=" + screenId + "&authTypeCd=" + authTypeCd + "&userAliasName=" + userAliasName;

    return fetch(url, {
        method: "GET",
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    })
}


export function getUserProfile() {

    // @ts-ignore
    const env = window['environments'];
    if(env !== undefined && env.no_auth == true){
        const userProfile = "./local/userProfile.json";


	console.log('Finished!');



    return fetch(userProfile);
    }
    return fetch(`./secure/user-profile?session-id=${getSessionId()}`);
}

export function logoutUser() {
    sessionStorage.removeItem('sessionId');
    sessionStorage.removeItem('authType');
    return fetch(`./secure/logout?auth_type=${getAuthType()}`);
}

export function validateSession() {
    // @ts-ignore
    const env = window['environments'];
    if(env !== undefined && env.no_auth == true){
        const validateSession = "./local/session.json";
        return fetch(validateSession);
    }
    return fetch(`./secure/validate-session`, {
        headers: {
            'session-id': getSessionId() as string
        }
    });
}
export function refresh() {
    return fetch(`./secure/refresh`);
}

export function getHostUri() {
    //sanitization
    const hrefEncode= encodeURIComponent(JSON.stringify(window.location.href));
    const sanitiiizedhref = JSON.parse(decodeURIComponent(hrefEncode));
    const url = new URL(sanitiiizedhref);
    const redirectUri = url.protocol + '//' + url.host;
    return redirectUri;
}

export function getTenantIds()
{
    // @ts-ignore
    const env = window['environments'];

    if(env !== undefined && env.no_auth == true){
        const tenantIds = "./local/tenantIds.json";
        return fetch(tenantIds);
    }
    return fetch(`./portunus/tenantId`,{
        headers: {
            'session-id': getSessionId() as string,
            'authtype': getAuthType() as string
        }
    });
}

export  function encrypt(text: string ){
    const pass =process.env.REACT_APP_API_AES_PASS;
    return CryptoJs.AES.encrypt(text,pass).toString();
}

export function decrypt(cipherText: string ){
    const pass =process.env.REACT_APP_API_AES_PASS;
    const bytes = CryptoJs.AES.decrypt(cipherText, pass);
    const text = bytes.toString(CryptoJs.enc.Utf8);
    return text;
}

export function getSessionId(){
    return decrypt(decrypt(String(sessionStorage.getItem('sessionId'))));
 }
 
 export function setSessionId(sessionId: string){
     sessionStorage.setItem("sessionId", encrypt(sessionId));
 }
 export function getAuthType(){
    return decrypt(decrypt(String(sessionStorage.getItem('authType'))));
 }
 
 export function setAuthType(authType: string){
     sessionStorage.setItem("authType", encrypt(authType));
 }

 export function fetchDGRolesLocal(tenantIds:any)
 {
 const env = window['environments'];
    if(env !== undefined && env.no_auth == true){
        const  mapping = "./local/mapping.json";
        return fetch(mapping);
    }
 }

 export function fetchDGRoles(tenantIds:any)
 {
    return axios.post(`/portunus/dac/roles/mapping`,tenantIds,
    {
        headers: {
            'session-id': getSessionId() as string,
            'authtype': getAuthType() as string
        }
    })
 } 


import React from 'react';
import './App.css';
import AppHeader from './app-header/AppHeader';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import IdleTimerContainer from './IdleTimerContainer';
import "@better-ui/styles/optum/optum.css";
import "@better-ui/styles/optum/optum.css";
import "@uitk/themes/optum/fonts.css";
import RefreshTimerContainer from './RefreshTimerContainer';

function App() {

  return (
    <React.Fragment>
      <AppHeader></AppHeader>
      <IdleTimerContainer></IdleTimerContainer>
      {/* <RefreshTimerContainer></RefreshTimerContainer> */}
    </React.Fragment>
  );
}

export default App;

import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import IdleTimer from 'react-idle-timer';
import Modal from 'react-modal'
import './login.css';
import * as AuthProxy from './security/AuthProxy';
import Logout from './layout/logout/Logout';


function IdleTimerContainer() {
    const idleTimerRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const modalSessionTimeOutRef = useRef(null);
    let idleTime = process.env.REACT_APP_IDLE_TIME;
    console.log('IdleTimerContainer process.env.REACT_APP_IDLE_TIME = ', process.env.REACT_APP_IDLE_TIME);
    if(idleTime === undefined){
        idleTime = 1200;
    }
    const onIdle = () => {
        console.log('User is Idle');
        setModalIsOpen(true);
        modalSessionTimeOutRef.current = setTimeout(logOut, 60 * 1000); //do selflogout after 1 minute
    }
    const stayActive = () => {
        setModalIsOpen(false);
        console.log('User is Active');
        clearTimeout(modalSessionTimeOutRef.current);
        AuthProxy.refresh()
        .then((res: any) => res.json())
        .then((data) => {
            AuthProxy.setSessionId(AuthProxy.encrypt(data['sessionId']));
        })
    }
    const logOut = () => {
        setModalIsOpen(false);
        clearTimeout(modalSessionTimeOutRef.current);
        return ReactDOM.render(
            <React.StrictMode>
                <Logout />
            </React.StrictMode>,
            document.getElementById('root')
        );
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="In Activity Found!" ariaHideApp={false}>
                <h2>You have been Idle for a while!</h2>
                <p>You will be logged out soon</p>
                <div>
                <input type="submit" className="btn button_red" value="Log Out" onClick={logOut} ></input>
                <input type="submit" className="btn button_green" value="Stay Active" onClick={stayActive}></input> 
                </div>
            </Modal>
            <IdleTimer ref={idleTimerRef}
                timeout={idleTime * 1000}
                onIdle={onIdle}>
            </IdleTimer>
        </div>
    );
}

export default IdleTimerContainer;

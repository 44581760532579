//import React from 'react';
import React, { FC } from "react";
import { Button } from "@better-ui/react";
import './index.css';
import './login.css';
import * as AuthProxy from './security/AuthProxy';
import logoPic from './assets/Optum-Rx-ora-RGB.svg'
import './app-header/AppHeader.css';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import AppFooter from "./app-header/app-footer/AppFooter";
import { generateFetchWithAuth } from './security/AuthentifiedFetch';
interface LoginProps { }

interface LoginState {
    line1: String;
    line2: String;
    line3: String;
    logo: string;
}
export default class Login extends React.Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            line1: "OptumRx benefits. Sign in using your MSID/OHID.",
            line2: "If you are unable to login using your credentials, contact your OptumRx client management or",
            line3: "helpdesk representative.",
            logo: "./images/Optum-Rx-ora-RGB.png"

        }
    }

    private logoSrc1 = './logo.png';
    private logoSrc = './images/Optum-Rx-ora-RGB.png';
    private loginImgSrc = './images/login.png';
    


    getConfProfile(screenId: any){
        AuthProxy.callConfService(screenId)
          .then((res: any) => res.json())
          .then((data) => {
            console.log(data);
            var parsable = "";
            if(data[0].screenConfiguration){
              parsable = data[0].screenConfiguration;
            }
            this.setState({line1: JSON.parse(parsable).loginText.line1});
            this.setState({line2: JSON.parse(parsable).loginText.line2});
            this.setState({line3: JSON.parse(parsable).loginText.line3});
            this.setState({logo: JSON.parse(parsable).logo});
        })
      }

    componentDidMount(): void {
        this.getConfProfile(1);
     }

    msidLogin(): void {
        const fileConfig = './navigation.json';

        fetch(fileConfig)
            .then((res: any): any => {
                return res.json();//as programInformations;
            })
            .then((env: any): any => {

                const query = new URLSearchParams(window.location.search);
                const code = query.get('code');
                if ((typeof (sessionStorage.getItem("sessionId")) == 'undefined' || sessionStorage.getItem("sessionId") == null)
                    && (code === null || code === 'undefined')) {
                    AuthProxy.setAuthType(AuthProxy.encrypt("msid"));
                    AuthProxy.loginRedirect();
                    return;
                }
            });
    }
    ohidLogin(): void {
        const fileConfig = './navigation.json';

        fetch(fileConfig)
            .then((res: any): any => {
                return res.json();//as programInformations;
            })
            .then((env: any): any => {

                const query = new URLSearchParams(window.location.search);
                const code = query.get('code');
                if ((typeof (sessionStorage.getItem("sessionId")) == 'undefined' || sessionStorage.getItem("sessionId") == null)
                    && (code === null || code === 'undefined')) {
                    AuthProxy.setAuthType(AuthProxy.encrypt('ohid'));
                    AuthProxy.loginRedirect();
                    return;
                }
            });
    }
    render(): React.ReactElement {
        return (
            <React.Fragment>                
                 <div className="mainWrap">
                    <header className="main">                          
                        <div className="logoPart">
                                <img src={logoPic} className="optum-logo" alt="OptumRx Logo"></img>
                                <br/>
                                <span className="subhead-large">RxClaim NextGen</span>
                            </div>                      
                        <br/>
                        <br/> 
                        <br/>      
                    </header>                  
                    <h1 className="bui-h1 center">Welcome to RxClaim NextGen </h1>                    
                    <div className="bui-body2 center">
                        <p>                        
                            This site allows employers and plan sponsors to manage their members                        
                            <div className="bui-body2 center">
                                 {this.state.line1}
                                 <br/>
                                 {this.state.line2}
                                 <br/>
                                 {this.state.line3}
                                 </div>                  
                                                                                                
                        </p>
                        <fieldset>                            
                            <Router>                                                                          
                                <input type="story--components-button--basic" className="bui-button is-primary" value="One Healthcare ID login" onClick={this.ohidLogin}></input>
                                <br />                                
                                <br />                                      
                                <input type="story--components-button--basic" className="bui-button is-primary" value="Employee MSID login  " onClick={this.msidLogin}></input>
                                </Router>                                
                            <div>
                                <AppFooter
                                /></div>
                        </fieldset>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

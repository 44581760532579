import React from "react";

import './ProviderLanding.css';

import * as AuthProxy from "../../security/AuthProxy";

import image from "../../assets/rxLiteLanding.jpg";
import asthmaImage from "../../assets/Icon_Asthma_RGB.svg";
import barChartImage from "../../assets/Icon_BarChart_RGB.png";
import chatImage from "../../assets/Icon_Chat_RGB.svg";
import { Button } from "primereact/button";
import { Card } from "@better-ui/react";
import { optionProperties } from "@uitk/react/*";

interface RxLiteProps { }

interface RxLiteState {
    card1: String;
    card1Desc : String;
    card1Pic: String;
    card2: String;
    card2Desc: String;
    card2Pic: String;
    card3: String;
    card3Desc: String;
    card3Pic: String;
    title: String;
    clinicalFlag: boolean;
    extPbmFlag: boolean;
    claimsFlag: boolean;
    rxLiteMemberURL: String;
    rxLiteClaimURL: String;
}
export default class RxLiteLanding extends React.Component<RxLiteProps,RxLiteState> {

    constructor(props: RxLiteProps) {
        super(props);
        this.state = {
            card1: "Claims Search",
            card1Desc : "Allows claim search by Member ID, Pharmacy, and Claim Number",
            card1Pic: "../../assets/Icon_Chat_RGB.svg",
            card2: "Card 2",
            card2Desc: "Card 2 Details",
            card2Pic: "../../assets/Icon_Asthma_RGB.svg",
            card3: "Card 3",
            card3Desc: "Card 3 Details",
            card3Pic: "../../assets/Icon_BarChart_RGB.png",
            title: "RxClaim Lite",
            clinicalFlag: false,
            extPbmFlag: false,
            claimsFlag: false,
            rxLiteMemberURL: "MemberSearch",
            rxLiteClaimURL: "RxClaimSearch"
        }
      }

      getConfProfile(screenId: any){
        AuthProxy.callConfService(screenId)
          .then((res: any) => res.json())
          .then((data) => {
            var parsable = "";
            if(data[0].screenConfiguration){
              parsable = data[0].screenConfiguration;
            }
            this.setState({card1: JSON.parse(parsable).cards.titles.card1});
            this.setState({card1Desc: JSON.parse(parsable).cards.descriptions.card1});
            this.setState({card1Pic: JSON.parse(parsable).cards.images.card1});
            this.setState({card2: JSON.parse(parsable).cards.titles.card2});
            this.setState({card2Desc: JSON.parse(parsable).cards.descriptions.card2});
            this.setState({card2Pic: JSON.parse(parsable).cards.images.card2});
            this.setState({card3: JSON.parse(parsable).cards.titles.card3});
            this.setState({card3Desc: JSON.parse(parsable).cards.descriptions.card3});
            this.setState({card3Pic: JSON.parse(parsable).cards.images.card3});
            this.setState({title: JSON.parse(parsable).title});
        })
      }

    componentDidMount(): void {
        //create new screen id and config profile w screen id 9 for rxlite landing page
        this.getConfProfile(9);
     }


    msidLogin(): void {

    }
    ohidLogin(): void {

    }

    render(): React.ReactElement {
        var showHideClaims = this.props.showHideClaims;
        var showHideMember = this.props.showHideMember;

        var card1Flag = (showHideClaims && this.state.card1.includes("Claims")) || (showHideMember && this.state.card1.includes("Member"));
        var card2Flag = (showHideClaims && this.state.card2.includes("Claims")) || (showHideMember && this.state.card2.includes("Member"));
        var card3Flag = (showHideClaims && this.state.card3.includes("Claims")) || (showHideMember && this.state.card3.includes("Member"));
        
        const env = window['environments'];

        return (
            <React.Fragment>
           <div className="grid custom">
                <div className="row">
                    <div className="col-l-6 zero-padding">
                        <div className="bui-grid-item-left">
                            <h1 className="margin-box-landing">
                                {this.state.title}</h1>
                        </div>


                    </div>
                    <div className="col-l-6 zero-padding">
                        <img className="brand-img" src={image} alt="two people smiling at each other" />
                    </div>
                </div>
            </div>
          <div className="grid float-row zero-padding">
           <div className="row specific">
                
          {card1Flag &&
            <Button className="unstyled-button" onClick={()=> this.props.updateRxLiteFrame(this.state.rxLiteClaimURL)}> 
            <Card 
              header={<h1>{this.state.card1}</h1>} className="bui-card-2"
            >
              <div className="imgBox"><img src={chatImage} alt="Stethoscope Icon" className="welcomeIcon" /></div>
              <div className="cardContent">{this.state.card1Desc}</div>

            </Card>
            </Button>
          }

          {card2Flag &&
            <Button className="unstyled-button" onClick={()=> this.props.updateRxLiteFrame(this.state.rxLiteMemberURL)}> 
            <Card header={<h1>{this.state.card2}</h1>} className="bui-card-2">
              <div className="imgBox"><img src={asthmaImage} alt="Stethoscope Icon" className="welcomeIcon" /></div>
              <div className="cardContent">{this.state.card2Desc}</div>

            </Card>
            </Button>
          }
          
          {card3Flag &&
            <Button  className="unstyled-button"> 
            <Card header={<h1>{this.state.card3}</h1>} className="bui-card-2">
              <div className="imgBox"><img src={barChartImage} alt="Stethoscope Icon" className="welcomeIcon" /></div>
              <div className="cardContent">{this.state.card3Desc}</div>

            </Card>
            </Button>
          }    
                    
            </div>
          </div>  

        </React.Fragment>
        );
    }
}

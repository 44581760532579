export default class PlanEditList {
    getPlanEditList() {
      const options = [
        {
            label: "Plan by Plan Code",
            path: "/plan-by-plan-code"
        }]
        let planEditMenu: any[] =[];
        options.forEach((optionValue) => {
            planEditMenu.push(optionValue);
        })
        return planEditMenu;
    }
}
export default class AccumsList {
    getAccumsList() {
      const options = [
        {
            label: "Deductible Schedule",
            path: "/deductible-schedule"
        },
        {
            label: "Out of Pocket Schedule",
            path: "/out-of-pocket-schedule",
        },
        {
            label: "Benefit Max Schedule",
            path: "/benefit-max-schedule",
        },
        {
            label: "Benefit Max Drug Status Schedule",
            path: "/benefit-max-drug-status-schedule",
        },
        {
            label: "Accumulation Codes",
            path: "/accumulation-code",
        },
        {
            label: "Accumulation Code List",
            path: "/accumulation-code-list",
        },
        {
            label: "Accumulator Override Schedule",
            path: "/accumulator-overrride-schedule",
        },
        {
            label: "TrOOP Schedule",
            path: "/troop-schedule",
        },
        {
            label: "Standard TrOOP Schedule",
            path: "/standard-troop-schedule",
        },
        {
            label: "Adjustment Reason Codes",
            path: "/adjustment-reason-code",
        },
        {
            label: "Adjustment Reason Code List",
            path: "/adjustment-reason-code-list",
        },
        {
            label: "LICS Schedule",
            path: "/lics-schedule",
        }]
        let accumsMenu: any[] =[];
        options.forEach((optionValue) => {
            accumsMenu.push(optionValue);
        })
        return accumsMenu;
    }
}
import * as React from "react";
import * as PortunusTools from '../utils/PortunusTools';

export default class PricingList {

  getPricingList(pricingResult: string[]) {
    if(pricingResult?.length ==0 ){
      return [];
    }
    const options = [
      {
        label: "Price Schedule",
        items: [
          {
            label: "Price Schedule",
            program: "RCPRC003",
            businessObject: "pricing.priceSchedule",
          },
          {
            label: "Price Table",
            program: "RCPTF031",
            businessObject: "pricing.pricetable",
          },
          {
            label: "Drug Cost Schedule",
            program: "RCDC3001",
            businessObject: "pricing.drugcostschedule",
          },
          {
            label: "Drug Cost Comparison Schedule",
            program: "RCDC5001",
            businessObject: "pricing.drugcostcomparisonschedule",
          },
        ],
      },

      {
        label: "Price Basis Schedule",
        program: "RCPS7001",
        businessObject: "pricing.priceBasisSchedule",
      },
      {
        label: "MAC List",
        items: [
          { label: "MAC List", 
            program: "RCMCL001",
            businessObject: "pricing.maclist",
          },
          {
            label: "MAC List Schedule",
            program: "RCMLS001",
            businessObject: "pricing.maclistschedule",
          },
          {
            label: "MAC List Identifier",
            program: "RCMLI001",
            businessObject: "pricing.maclistidentifier",
          },
        ],
      },
      {
        label: "Miscellaneous Schedules",
        items: [
          {
            label: "Tax Schedule",
            program: "RCTAX001",
            businessObject: "pricing.taxschedule",
          },
          {
            label: "Withhold Schedule",
            program: "RCWSH001",
            businessObject: "pricing.withholdschedule",
          },
          {
            label: "Amount Due Schedule",
            program: "RCADS001",
            businessObject: "pricing.amountdueschedule",
          },
          {
            label: "Fee Schedule",
            program: "RCFSH001",
            businessObject: "pricing.feeschedule",
          },
          {
            label: "State Fee Schedule",
            program: "RCSFS001",
            businessObject: "pricing.statefeeschedule",
          },
          {
            label: "RxPrice Schedule",
            program: "RCRP1001",
            businessObject: "pricing.rxpriceschedule",
          },
          {
            label: "Rx Restriction Schedule",
            program: "RCRRD001",
            businessObject: "pricing.rxrestrictionschedule",
          },
          {
            label: "Rate Percent Override Schedule",
            program: "RCNRB001",
            businessObject: "pricing.ratepercentoverrideschedule",
          },
          {
            label: "Pharmacy Network Tax Schedule",
            program: "RCTXP001",
            businessObject: "pricing.pharmacynetworktaxschedule",
          },
        ],
      },

      {
        label: "Professional Service Fee Table",
        items: [
          {
            label: "Professional Service Fee Table",
            program: "RCSFT001",
            businessObject: "pricing.professionalservicefeetable",
          },
          {
            label: "Professional Service Fee Schedule",
            program: "RCSFH001",
            businessObject: "pricing.professionalservicefeeschedule",
          },
        ],
      },

      {
        label: "Administrative Fee Schedule",
        items: [
          {
            label: "Administrative Fee Schedule",
            program: "RCAFS001",
            businessObject: "pricing.administrativefeeschedule",
          },
          {
            label: "Administrative Fee Profile",
            program: "RCAFR001",
            businessObject: "pricing.administrativefeeprofile",
          },
        ],
      },

      {
        label: "Retail & Mail Table",
        program: "RCRMT001",
        businessObject: "pricing.retailmailtable",
      },
      {
        label: "Tax Rate Codes",
        program: "RCTRC001",
        businessObject: "pricing.taxratecodes",
      },
      {
        label: "State Pricing Table",
        program: "RCSPT001",
        businessObject: "pricing.statepricingtable",
      },
      {
        label: "POS Rebate List",
        program: "RCRBL001",
        businessObject: "pricing.posrebatelist",
      },
      {
        label: "Cost Source/Type List",
        program: "RCSCH001",
        businessObject: "pricing.costsourcetypelist",
      },
      {
        label: "Real Time POS Rebate Table",
        program: "RCDXR001",
        businessObject: "pricing.realtimeposrebatetable",
      },
    ];

    let abilitiesMap= new Map();
    pricingResult.forEach(ability=>{
      let abilitySplits = ability.split(".");
      if(abilitySplits?.length>=3){
        var verbModifier = abilitySplits[abilitySplits?.length-2]+"."+abilitySplits[abilitySplits?.length-1]
        for(var i = abilitySplits?.length-2,j=0 ;i>0; i--,j++){
          var businessObject = "";
          for(var k=0;j>=0;j--,k++){
            if(j===0) businessObject = abilitySplits[k];
            else businessObject = businessObject+"."+abilitySplits[k];
          }
          if(!abilitiesMap.has(businessObject)){
            abilitiesMap.set(businessObject, new Array<string>());
          }
          abilitiesMap.get(businessObject)?.push(verbModifier);
        }
      }
    })
    let pricingMenu: any[] =[];
    console.log('getModulesPricing =', pricingResult);
    // groupBy key and values, eg. Prescriber and corresponding value {prescriberid,sanctioncode ,..}
    let map = new Map<string, Array<string>>();
    for (let i = 0; i < pricingResult.length; i++) {
      let name = pricingResult[i].split(".")[1];
      let platformName = pricingResult[i].split(".")[0];
      if (!map.has(name)) {
        map.set(name, new Array<string>());
      }

      const lenthOfResultArray = pricingResult[i].split(".").length;
      map
        .get(name)
        ?.push(pricingResult[i].replace(platformName + "." +  name + ".", ""));
    }//
    options.forEach((optionValue) => {
    
      if(optionValue?.businessObject){  
        if(PortunusTools.keyIsEligibleForMenuForming(optionValue?.businessObject, map)){
          pricingMenu.push(optionValue);
        }
      }else if(optionValue?.items){         
        let pricingSubMenu: any[] =[];
        optionValue.items.forEach((optionItem) =>{ 
          if(optionItem?.businessObject){
            if(PortunusTools.keyIsEligibleForMenuForming(optionItem?.businessObject, map)){
              pricingSubMenu.push(optionItem);
            }
          }
        })
        if(pricingSubMenu?.length >0){
          let menuObj: menu = { label: optionValue.label, items: [] };
          menuObj.items = pricingSubMenu;
          pricingMenu.push(menuObj);
        }
      }
    });

    return pricingMenu;
  }
}

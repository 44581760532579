export default class PatientPayList {
    getPatientPayList() {
      const options = [
        {
            label: "Patient Pay Schedule",
            path: "/patient-pay-schedule"
        },
        {
            label: "Patient Pay Table",
            path: "/patient-pay-table"
        },
        {
            label: "Copay Schedule",
            path: "/copay-schedule"
        },
        {
            label: "Additional Patient Pay Schedule",
            path: "/additional-patient-pay-schedule"
        },
        {
            label: "Discount Patient Pay Table",
            path: "/discount-patient-pay-table"
        },
        {
            label: "Processor Fee Table",
            path: "/processor-fee-table"
        },
        {
            label: "Coupon Patient Pay GPI List",
            path: "/coupon-patient-pay-gpi-list"
        },
        {
            label: "Max Copay Schedule",
            path: "/max-copay-schedule"
        },
        {
            label: "Price Edge Schedule",
            path: "/price-edge-schedule"
        }]
        let patientPayMenu: any[] =[];
        options.forEach((optionValue) => {
            patientPayMenu.push(optionValue);
        })
        return patientPayMenu;
    }
}
import React from "react";

import './ProviderLanding.css';

import * as AuthProxy from "../../security/AuthProxy";
import image from "../../assets/AccumsImage.jpg";
import asthmaImage from "../../assets/Icon_Asthma_RGB.svg";
import barChartImage from "../../assets/Icon_BarChart_RGB.png";
import chatImage from "../../assets/Icon_Chat_RGB.svg";
import { Button } from "primereact/button";
import { Card } from "@better-ui/react";
import AppHeader from "../../app-header/AppHeader";

interface PlanProps { }



interface PlanState {
    card1: string;
    card1Desc : string;
    card1Pic: string;
    card2: string;
    card2Desc: string;
    card2Pic: string;
    card3: string;
    card3Desc: string;
    card3Pic: string;
    title: string;  


}
export default class PlanLanding extends React.Component<PlanProps, PlanState> {

    constructor(props: PlanProps) {
        super(props);
        this.state = {
            card1: "Card 1",
            card1Desc : "Card 1 text",
            card1Pic: "../../assets/Icon_Chat_RGB.svg",
            card2: "Card 2",
            card2Desc: "Card 2 text",
            card2Pic: "../../assets/Icon_Asthma_RGB.svg",
            card3: "Card 3",
            card3Desc: "Card 3 text",
            card3Pic: "../../assets/Icon_BarChart_RGB.png",
            title: "Plan"
        }
      }

    // getConfProfile(screenId: any){

        
    //     AuthProxy.callConfService(screenId)
    //       .then((res: any) => res.json())
    //       .then((data) => {
    //         console.log(data);
    //         var parsable = "";
    //         if(data[0].screenConfiguration){
    //           parsable = data[0].screenConfiguration;
    //         }
    //         this.setState({card1: JSON.parse(parsable).cards.titles.card1});
    //         this.setState({card1Desc: JSON.parse(parsable).cards.descriptions.card1});
    //         this.setState({card1Pic: JSON.parse(parsable).cards.images.card1});
    //         this.setState({card2: JSON.parse(parsable).cards.titles.card2});
    //         this.setState({card2Desc: JSON.parse(parsable).cards.descriptions.card2});
    //         this.setState({card2Pic: JSON.parse(parsable).cards.images.card2});
    //         this.setState({card3: JSON.parse(parsable).cards.titles.card3});
    //         this.setState({card3Desc: JSON.parse(parsable).cards.descriptions.card3});
    //         this.setState({card3Pic: JSON.parse(parsable).cards.images.card3});
    //         this.setState({title: JSON.parse(parsable).title});
    //     })
    //   }

    componentDidMount(): void {
        //this.getConfProfile(5);
     }

    msidLogin(): void {

    }
    ohidLogin(): void {

    }

    render(): React.ReactElement {
        console.log(this.props);
        const env = window['environments'];
        //var tenantId = this.props.currentTenant;
        
        // let pharmNetwork = "";
        // let superNetwork = "";
        // let prescriber = "";
        // if(process.env.REACT_APP_API_ENV == "local" || process.env.REACT_APP_API_ENV == "dev" || process.env.REACT_APP_API_ENV == "qa") {
        //     pharmNetwork = `${env.pharmacyNetwork}/program/RCPHN002/?tenantId=${tenantId}`;
        //     superNetwork = `${env.pharmacyNetwork}/program/RCSNH002/?tenantId=${tenantId}`;
        //     prescriber = `${env.transformed}/program/RCPRF002/?tenantId=${tenantId}`;
        // } else {
        //     pharmNetwork = `${process.env.REACT_APP_PHARMACY_NETWORK_URL}/program/RCPHN002/?tenantId=${tenantId}`;
        //     superNetwork = `${process.env.REACT_APP_PHARMACY_NETWORK_URL}/program/RCSNH002/?tenantId=${tenantId}`;
        //     prescriber = `${process.env.REACT_APP_PRESCRIBER_URL}/program/RCPRF002/?tenantId=${tenantId}`;
        // }

        console.log("Try calling update frame");
        //this.props.updateFrameFromChild(pharmNetwork);

        return (
            <React.Fragment>
                {/* {console.log(this.props.providerData)} */}
               <div className="grid">
                    <div className="row">
                        <div className="col-l-6 zero-padding">
                            <div className="bui-grid-item-left">
                                <h1 className="margin-box-landing">
                                    {this.state.title}</h1>
                            </div>


                        </div>
                        <div className="col-l-6 zero-padding">
                            <img className="brand-img" src={image} alt="two people smiling at each other" />
                        </div>
                    </div>
                </div>
                <div className="grid float-row zero-padding">
                    <div className="row">
                    
          
              <Button className="unstyled-button"> 
              <Card 
                header={<h1>{this.state.card1}</h1>} className="bui-card-2"
              >
                <div className="imgBox"><img src={chatImage} alt="Stethoscope Icon" className="welcomeIcon" /></div>
                <div className="cardContent">{this.state.card1Desc}</div>

              </Card>
              </Button>

              <Button  className="unstyled-button"> 
              <Card 
                header={<h1>{this.state.card2}</h1>} className="bui-card-2"
              >
                <div className="imgBox"><img src={asthmaImage} alt="Stethoscope Icon" className="welcomeIcon" /></div>
                <div className="cardContent">{this.state.card2Desc}</div>

              </Card>
              </Button>
             
              <Button className="unstyled-button"> 
              <Card 
                header={<h1>{this.state.card3}</h1>} className="bui-card-2"
              >
                <div className="imgBox"><img src={barChartImage} alt="Stethoscope Icon" className="welcomeIcon" /></div>
                <div className="cardContent">{this.state.card3Desc}</div>

              </Card>
              </Button>
                       
                        
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

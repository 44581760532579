import React from "react";

import './ProviderLanding.css';

import * as AuthProxy from "../../security/AuthProxy";

import image from "../../assets/operationsLandingCompressed.jpg";
import asthmaImage from "../../assets/Icon_Asthma_RGB.svg";
import barChartImage from "../../assets/Icon_BarChart_RGB.png";
import chatImage from "../../assets/Icon_Chat_RGB.svg";
import { Button } from "primereact/button";
import { Card } from "@better-ui/react";
import { optionProperties } from "@uitk/react/*";

interface OpProps { }

interface OpState {
    card1: String;
    card1Desc : String;
    card1Pic: String;
    card2: String;
    card2Desc: String;
    card2Pic: String;
    card3: String;
    card3Desc: String;
    card3Pic: String;
    title: String;
    clinicalFlag: boolean;
    extPbmFlag: boolean;
    claimsFlag: boolean;
}
export default class OperationsLanding extends React.Component<OpProps,OpState> {

    constructor(props: OpProps) {
        super(props);
        this.state = {
            card1: "Clinical Automation Tool",
            card1Desc : "Allows for configuration on Clinical Automation Tool",
            card1Pic: "../../assets/Icon_Chat_RGB.svg",
            card2: "External PBM",
            card2Desc: "Allows for configuration on External PBM",
            card2Pic: "../../assets/Icon_Asthma_RGB.svg",
            card3: "Claims",
            card3Desc: "Allows for configuration on Claims",
            card3Pic: "../../assets/Icon_BarChart_RGB.png",
            title: "Operations",
            clinicalFlag: false,
            extPbmFlag: false,
            claimsFlag: false,
        }
      }

      getConfProfile(screenId: any){
        AuthProxy.callConfService(screenId)
          .then((res: any) => res.json())
          .then((data) => {
            console.log(data);
            var parsable = "";
            if(data[0].screenConfiguration){
              parsable = data[0].screenConfiguration;
            }
            this.setState({card1: JSON.parse(parsable).cards.titles.card1});
            this.setState({card1Desc: JSON.parse(parsable).cards.descriptions.card1});
            this.setState({card1Pic: JSON.parse(parsable).cards.images.card1});
            this.setState({card2: JSON.parse(parsable).cards.titles.card2});
            this.setState({card2Desc: JSON.parse(parsable).cards.descriptions.card2});
            this.setState({card2Pic: JSON.parse(parsable).cards.images.card2});
            this.setState({card3: JSON.parse(parsable).cards.titles.card3});
            this.setState({card3Desc: JSON.parse(parsable).cards.descriptions.card3});
            this.setState({card3Pic: JSON.parse(parsable).cards.images.card3});
            this.setState({title: JSON.parse(parsable).title});
        })
      }

    componentDidMount(): void {
        this.getConfProfile(7);
     }


    msidLogin(): void {

    }
    ohidLogin(): void {

    }

    render(): React.ReactElement {
        console.log(this.props);
        const env = window['environments'];
        var tenantId = this.props.currentTenant;   
        
        let catUrl = "";
        let perksUrl = "";
        let rxviewUrl = "";
        if(process.env.REACT_APP_API_ENV == "local" || process.env.REACT_APP_API_ENV == "dev") {
            catUrl = `https://rxc-cat-dev.optum.com/`;
            perksUrl = `https://perks-frontend-dev-ctc.hcck8s-ctc.optum.com/`;
            rxviewUrl = `https://rxview-dev.optum.com/`;
        } else if(process.env.REACT_APP_API_ENV == "qa"){
            catUrl = `https://rxc-cat-test.optum.com/`;
            perksUrl = `https://perks-frontend-qa-ctc.hcck8s-ctc.optum.com/`;
            rxviewUrl = `https://rxview-qa.optum.com/`;
        } else if(process.env.REACT_APP_API_ENV == "stg"){            
            perksUrl = `https://perks-frontend-stg.optum.com/`;
            rxviewUrl = `https://rxview-service-stg.optum.com/`;
        } else if(process.env.REACT_APP_API_ENV == "prd"){                        
            perksUrl = `https://perks-frontend.optum.com/`;
            rxviewUrl = `https://rxview-frontend.optum.com/`;
        }

        console.log("Try calling update frame");
        return (
            <React.Fragment>
                {console.log(this.props.operationsData)}
               <div className="grid">
                    <div className="row">
                        <div className="col-l-6 zero-padding">
                            <div className="bui-grid-item-left">
                                <h1 className="margin-box-landing">
                                {this.state.title} </h1>
                            </div>


                        </div>
                        <div className="col-l-6 zero-padding">
                            <img className="brand-img" src={image} alt="two people smiling at each other" />
                        </div>
                    </div>
                </div>
                <div className="grid float-row zero-padding">
                    <div className="row"> 
                    { this.props.operationsData.length !== undefined && this.props.operationsData.map(result => {
                        
                        if ((result.includes("clinicalautomationtool.access.grant") || result.includes("clinicalautomationtool.read.grant"))
                            && !this.state.clinicalFlag) {
                            this.setState({clinicalFlag: true})
                        } 
                                                
                        if ((result.includes("extpbm.all.access.grant") || result.includes("extpbm.all.read.grant"))
                            && !this.state.extPbmFlag) {
                            this.setState({extPbmFlag: true})
                        } 

                        if ((result.includes("Claims.all.access.grant") || result.includes("Claims.all.read.grant"))
                            && !this.state.claimsFlag) {
                            this.setState({claimsFlag: true})
                        } 

                    })} 
                       
             {this.state.clinicalFlag &&
             <Button onClick={()=> this.props.updateFrameFromChild(catUrl)} className="unstyled-button"> 
             <Card
                 header={<h1>{this.state.card1}</h1>} className="bui-card-2"
                     >
               <div className="imgBox"><img src={chatImage} alt="imgIcon" className="welcomeIcon" /></div>                    
               <div className="cardContent">{this.state.card1Desc}</div>
               </Card>
               </Button>}

              {this.state.extPbmFlag &&    
                <Button onClick={()=> this.props.updateFrameFromChild(perksUrl)} className="unstyled-button"> 
                <Card
                 header={<h1>{this.state.card2}</h1>} className="bui-card-2"
                 >
                <div className="imgBox"><img src={asthmaImage} alt="imgIcon" className="welcomeIcon" /></div>
                <div className="cardContent">{this.state.card2Desc}</div>
                </Card>
               </Button>}

               {this.state.claimsFlag &&    
                <Button onClick={()=> this.props.updateFrameFromChild(rxviewUrl)} className="unstyled-button"> 
                <Card
                header={<h1>{this.state.card3}</h1>} className="bui-card-2"
                >
                <div className="imgBox"><img src={barChartImage} alt="imgIcon" className="welcomeIcon" /></div>
                <div className="cardContent">{this.state.card3Desc}</div>
                </Card>
                </Button>}                   
            
            
                 </div>
                </div>

            </React.Fragment>
        );
    }
}

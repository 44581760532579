import React from "react";

import './ProviderLanding.css';

import * as AuthProxy from "../../security/AuthProxy";

import image from "../../assets/pricinglanding_compressed.jpg";
import asthmaImage from "../../assets/Icon_Asthma_RGB.svg";
import barChartImage from "../../assets/Icon_BarChart_RGB.png";
import chatImage from "../../assets/Icon_Chat_RGB.svg";

interface PriceProps { }

interface PriceState {
    card1: String;
    card1Desc : String;
    card1Pic: String;
    card2: String;
    card2Desc: String;
    card2Pic: String;
    card3: String;
    card3Desc: String;
    card3Pic: String;
    title: String;
}
export default class PricingLanding extends React.Component<PriceProps,PriceState> {

    constructor(props: PriceProps) {
        super(props);
        this.state = {
            card1: "Price Table",
            card1Desc : "Allows for configuration on Price Table",
            card1Pic: "../../assets/Icon_Chat_RGB.svg",
            card2: "Price Schedule",
            card2Desc: "Allows for configuration on Price Schedule",
            card2Pic: "../../assets/Icon_Asthma_RGB.svg",
            card3: "Drug Cost Scheduler",
            card3Desc: "Allows for configuration on Drug Cost Scheduler",
            card3Pic: "../../assets/Icon_BarChart_RGB.png",
            title: "Price"
        }
      }

      getConfProfile(screenId: any){
        AuthProxy.callConfService(screenId)
          .then((res: any) => res.json())
          .then((data) => {
            console.log(data);
            var parsable = "";
            if(data[0].screenConfiguration){
              parsable = data[0].screenConfiguration;
            }
            this.setState({card1: JSON.parse(parsable).cards.titles.card1});
            this.setState({card1Desc: JSON.parse(parsable).cards.descriptions.card1});
            this.setState({card1Pic: JSON.parse(parsable).cards.images.card1});
            this.setState({card2: JSON.parse(parsable).cards.titles.card2});
            this.setState({card2Desc: JSON.parse(parsable).cards.descriptions.card2});
            this.setState({card2Pic: JSON.parse(parsable).cards.images.card2});
            this.setState({card3: JSON.parse(parsable).cards.titles.card3});
            this.setState({card3Desc: JSON.parse(parsable).cards.descriptions.card3});
            this.setState({card3Pic: JSON.parse(parsable).cards.images.card3});
            this.setState({title: JSON.parse(parsable).title});
        })
      }

    componentDidMount(): void {
        this.getConfProfile(6);
     }

    msidLogin(): void {

    }
    ohidLogin(): void {

    }

    render(): React.ReactElement {
        return (
            <React.Fragment>
               <div className="grid">
                    <div className="row">
                        <div className="col-l-6 zero-padding">
                            <div className="bui-grid-item-left">
                                <h1 className="margin-box-landing">
                                {this.state.title} </h1>
                            </div>


                        </div>
                        <div className="col-l-6 zero-padding">
                            <img className="brand-img" src={image} alt="two people smiling at each other" />
                        </div>
                    </div>
                </div>
                <div className="grid float-row bui-section zero-padding">
                    <div className="row">
                        <div className="col-l-4 zero-padding">
                                <h4 className="sub-heading zero-margin"> <img className="imgIcon" src={chatImage}/>{this.state.card1}</h4>
                                <span className="second-row"> {this.state.card1Desc}
                                 </span>
                        </div>
                        <div className="col-l-4 zero-padding">
                            <h4 className="sub-heading zero-margin"> <img className="imgIcon" src={asthmaImage}/>{this.state.card2}</h4>
                            <span className="second-row"> {this.state.card2Desc}                                    
                             </span>
                        </div>
                        <div className="col-l-4 zero-padding">
                            <h4 className="sub-heading zero-margin"> <img className="imgIcon" src={barChartImage}/>{this.state.card3}</h4>
                            <span className="second-row"> {this.state.card3Desc}                             
                            </span>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
import * as React from "react";
import * as PortunusTools from "../utils/PortunusTools";

export default class PlanList {
  getPlanList(planResult: string[]) {
    if (planResult?.length == 0) {
      return [];
    }
    const options = [
      {
        label: "Plan",
        items: [
          {
            label: "Plan",
            program: "RCPLN007",
            businessObject: "plan.plan",
          },
        ],
      },
    ];

    let abilitiesMap = new Map();
    planResult.forEach((ability) => {
      let abilitySplits = ability.split(".");
      if (abilitySplits?.length >= 3) {
        var verbModifier =
          abilitySplits[abilitySplits?.length - 2] +
          "." +
          abilitySplits[abilitySplits?.length - 1];
        for (var i = abilitySplits?.length - 2, j = 0; i > 0; i--, j++) {
          var businessObject = "";
          for (var k = 0; j >= 0; j--, k++) {
            if (j === 0) businessObject = abilitySplits[k];
            else businessObject = businessObject + "." + abilitySplits[k];
          }
          if (!abilitiesMap.has(businessObject)) {
            abilitiesMap.set(businessObject, new Array<string>());
          }
          abilitiesMap.get(businessObject)?.push(verbModifier);
        }
      }
    });
    let planMenu: any[] = [];
    console.log("getModulesPlan =", planResult);
    // groupBy key and values, eg. Prescriber and corresponding value {prescriberid,sanctioncode ,..}
    let map = new Map<string, Array<string>>();
    for (let i = 0; i < planResult.length; i++) {
      let name = planResult[i].split(".")[1];
      let platformName = planResult[i].split(".")[0];
      if (!map.has(name)) {
        map.set(name, new Array<string>());
      }

      const lenthOfResultArray = planResult[i].split(".").length;
      map
        .get(name)
        ?.push(planResult[i].replace(platformName + "." + name + ".", ""));
    } //
    options.forEach((optionValue) => {
      if (optionValue?.businessObject) {
        if (
          PortunusTools.keyIsEligibleForMenuForming(
            optionValue?.businessObject,
            map
          )
        ) {
          planMenu.push(optionValue);
        }
      } else if (optionValue?.items) {
        let planSubMenu: any[] = [];
        optionValue.items.forEach((optionItem) => {
          if (optionItem?.businessObject) {
            if (
              PortunusTools.keyIsEligibleForMenuForming(
                optionItem?.businessObject,
                map
              )
            ) {
              planSubMenu.push(optionItem);
            }
          }
        });
        if (planSubMenu?.length > 0) {
          let menuObj: menu = { label: optionValue.label, items: [] };
          menuObj.items = planSubMenu;
          planMenu.push(menuObj);
        }
      }
    });

    return planMenu;
  }
}

import React, {useEffect, useState} from 'react';
export const Member = (props: any) => {
    console.log('In NextgenUi Member: props=', props);
    const {context} = props;
    const ref = React.useRef(null);
    const [viewName, setViewName] = useState<string>('memberSearch');
    let newContext = JSON.stringify(context);
    const [memberContext, setMemberContext] = React.useState(newContext);
    useEffect(() => {
        const handleEvent = (e: any) => {
            console.log('In NextgenUi Member: handleEvent', e);
            const {detail} = e;

            // eslint-disable-next-line default-case
            switch (detail.type) {
                case '[MEMBER] ROUTE': {
                    console.log('In NextgenUi Member: detail=', detail);
                    newContext = JSON.stringify({...context, searchDetailParam: detail.payload.searchDetailParam});
                    console.log('In NextgenUi Member: newContext=', newContext);
                    setMemberContext(newContext);
                    if (detail.payload.viewName) {
                        setViewName(detail.payload.viewName);
                    }
                    break;
                }
            }
        };

        const curentRef = ref.current;
        console.log('In NextgenUi Member:: curentRef=', curentRef);
        setMemberContext(JSON.stringify(props.context));

        if(curentRef) {
            curentRef.addEventListener('event', handleEvent);
        }
        return () => {
            curentRef.removeEventListener('event', handleEvent);
        };
    }, [props.context]);
    return (
        // @ts-ignore
        <member-mp ref={ref}  view={viewName}  id="member-webcomponent" text="Member" context={memberContext}/>
    );
};

export default Member;

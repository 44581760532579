import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Logout from '../logout/Logout';

function InvalidSessionModal() {
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const getLogoutPage = () => {
        setModalIsOpen(false);
            // do redirect to logout
            return ReactDOM.render(
                <React.StrictMode>
                    <Logout />
                </React.StrictMode>,
                document.getElementById('root')
            );
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
        <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="In Activity Found!" ariaHideApp={false}>
            <h2>Your Session is Invalid</h2>
            <div style={{textAlign: 'center'}} >
                <input type="submit" className="btn button_red" value="Ok" onClick={getLogoutPage} ></input>
            </div>
        </Modal>
    </div>
    );
}

export default InvalidSessionModal;
